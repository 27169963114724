import React from "react";
import {Stack, Text, Link, Flex, Grid} from "@chakra-ui/core";
import InstagramIcon from "@pency/components/icons/Instagram";
import FacebookIcon from "@pency/components/icons/Facebook";
import WhatsappIcon from "@pency/components/icons/WhatsApp";
import TwitterIcon from "@pency/components/icons/Twitter";

import Content from "~/ui/structure/Content";
import {getLocale} from "~/i18n/selectors";
import {useTranslation} from "~/i18n/hooks";

const Footer: React.FC = () => {
  const locale = getLocale();
  const t = useTranslation();

  return (
    <Content
      backgroundColor="primary.900"
      color="primary.100"
      marginTop="auto"
      paddingX={6}
      paddingY={{base: 12, md: 12}}
    >
      <Stack spacing={{base: 8, md: 10}}>
        <Flex
          alignItems="flex-start"
          borderBottom="1px solid"
          borderColor="primary.800"
          flexDirection={{base: "column", md: "row"}}
          justifyContent="space-between"
          paddingBottom={{base: 8, md: 10}}
        >
          <Grid
            gridGap={{base: 8}}
            gridTemplateColumns={{
              base: "repeat(auto-fit, auto)",
              sm: "repeat(2, auto)",
              md: "repeat(6, auto)",
            }}
            marginBottom={{base: 8, md: 0}}
          >
            <Link _hover={{color: "primary.200"}} href={`/${locale}`}>
              {t("menu.home")}
            </Link>
            <Link
              _hover={{color: "primary.200"}}
              href={`${locale ? `/${locale}` : ""}/${t("menu.plans.url")}`}
            >
              {t("menu.plans.label")}
            </Link>
            <Link
              _hover={{color: "primary.200"}}
              href={`${locale ? `/${locale}` : ""}/${t("menu.aboutUs.url")}`}
            >
              {t("menu.aboutUs.label")}
            </Link>
            <Link
              _hover={{color: "primary.200"}}
              href={`${locale ? `/${locale}` : ""}/${t("menu.faqs.url")}`}
            >
              {t("menu.faqs.label")}
            </Link>
            <Link
              isExternal
              _hover={{
                color: "primary.200",
              }}
              href={`${process.env.LINKS_URL}/${t("menu.help.url")}`}
            >
              {t("menu.help.label")}
            </Link>
          </Grid>
          <Stack isInline spacing={6}>
            <Stack isInline alignItems="center" spacing={1}>
              <Link
                isExternal
                _hover={{color: "primary.200"}}
                href={`https://instagram.com/${t("menu.socialLinks.instagram")}`}
                marginLeft={1}
              >
                <InstagramIcon size={{base: 6}} />
              </Link>
            </Stack>
            <Stack isInline alignItems="center" spacing={1}>
              <Link
                isExternal
                _hover={{color: "primary.200"}}
                href={`https://wa.me/${process.env.MANTAINER_PHONE}`}
                marginLeft={1}
              >
                <WhatsappIcon size={6} />
              </Link>
            </Stack>
            <Stack isInline alignItems="center" spacing={1}>
              <Link
                isExternal
                _hover={{color: "primary.200"}}
                href={`https://facebook.com/${t("menu.socialLinks.facebook")}`}
                marginLeft={1}
              >
                <FacebookIcon size={{base: 6}} />
              </Link>
            </Stack>
            <Stack isInline alignItems="center" spacing={1}>
              <Link
                isExternal
                _hover={{color: "primary.200"}}
                href={`https://twitter.com/${t("menu.socialLinks.twitter")}`}
                marginLeft={1}
              >
                <TwitterIcon size={{base: 6}} />
              </Link>
            </Stack>
          </Stack>
        </Flex>
        <Flex
          color="primary.400"
          flexDirection={{base: "column", md: "row"}}
          fontSize="sm"
          justifyContent="space-between"
        >
          <Text>
            © {new Date().getFullYear()} {t("footer.copyright")}
          </Text>
          <Stack isInline marginTop={{base: 4, md: 0}} spacing={6}>
            <Link
              isExternal
              _hover={{color: "primary.200"}}
              // Se tiene que cambiar la url - PROD_CHANGE 
              href="https://landing-git-feature-newlanding-pencyapp.vercel.app/politica-de-privacidad"
            >
              {t("footer.privacy")}
            </Link>
            <Link
              isExternal
              _hover={{color: "primary.200"}}
              href="https://enterprise.wibson.io/#/policy/cookie/4644b94f-bb9e-4f5b-96ec-e6587759d874"
            >
              {t("footer.cookie")}
            </Link>
            <Link
              isExternal
              _hover={{color: "primary.200"}}
              href="https://enterprise.wibson.io/#/data-request/user/4644b94f-bb9e-4f5b-96ec-e6587759d874"
            >
              {t("footer.data")}
            </Link>
          </Stack>
        </Flex>
      </Stack>
    </Content>
  );
};

export default Footer;
