import React from "react";
import {Stack, Link, StackProps, Icon} from "@chakra-ui/core";
import Logo from "@pency/components/static/Logo";
import Button from "@pency/components/controls/Button";
import Drawer, {DrawerBody} from "@pency/components/structure/Drawer";
import InstagramIcon from "@pency/components/icons/Instagram";
import FacebookIcon from "@pency/components/icons/Facebook";
import WhatsAppIcon from "@pency/components/icons/WhatsApp";
import TwitterIcon from "@pency/components/icons/Twitter";
import MenuIcon from "@pency/components/icons/Menu";
import UnstyledLink from "@pency/components/controls/Link";

import Content from "~/ui/structure/Content";
import Heading from "~/ui/display/Heading";
import {getLocale} from "~/i18n/selectors";
import {useTranslation} from "~/i18n/hooks";

const LINKS = (t) => [
  {
    label: t("menu.home"),
    url: "/",
  },
  {
    label: t("menu.plans.label"),
    url: t("menu.plans.url"),
  },
  {
    label: t("menu.faqs.label"),
    url: t("menu.faqs.url"),
  },
  {
    label: t("menu.help.label"),
    url: `${process.env.LINKS_URL}/${t("menu.help.url")}`,
  },
  {
    label: t("menu.login"),
    url: process.env.SIGNIN_URL,
    isExternal: true,
  },
  {
    label: t("menu.signUp"),
    url: process.env.SIGNUP_URL,
    isExternal: true,
  },
];

const Navbar: React.FC<StackProps> = ({color, ...props}) => {
  const [isToggled, toggle] = React.useState<boolean>(false);
  const locale = getLocale();
  const t = useTranslation();

  function handleToggle() {
    toggle(!isToggled);
  }

  return (
    <Content color={color} paddingX={6} {...props}>
      <Stack isInline alignItems="center" justifyContent="space-between" paddingY={6}>
        <Stack isInline shouldWrapChildren spacing={12}>
          <Link _focus={{ border: "0px"}} href={`/${locale}`}>
            <UnstyledLink>
              <Logo />
            </UnstyledLink>
          </Link>
          <Stack isInline shouldWrapChildren display={{base: "none", lg: "inherit"}} spacing={8}>
            <Link _focus={{ border: "0px"}} href={`${locale ? `/${locale}` : ""}/${t("menu.plans.url")}`}>
              {t("menu.plans.label")}
            </Link>
            <Link _focus={{ border: "0px"}} href={`${locale ? `/${locale}` : ""}/${t("menu.aboutUs.url")}`}>
              {t("menu.aboutUs.label")}
            </Link>
            <Link _focus={{ border: "0px"}} isExternal href={`${process.env.LINKS_URL}/${t("menu.help.url")}`}>
              {t("menu.help.label")}
            </Link>
          </Stack>
        </Stack>
        <Stack isInline spacing={4}>
          <UnstyledLink isExternal href={`${process.env.SIGNIN_URL}/${locale}`}>
            <Button
              _hover={{backgroundColor: "transparent", color: "white"}}
              backgroundColor="transparent"
              color={color}
              display={{base: "none", lg: "inherit"}}
              variantColor="primary"
              _focus={{ border: "0px"}}
              variant="outline"
            >
              {t("menu.login")}
            </Button>
          </UnstyledLink>
          <UnstyledLink href={`${process.env.SIGNUP_URL}/${locale}`}>
            <Button
              _hover={{boxShadow: "lg"}}
              display={{base: "none", lg: "inherit"}}
              color={"green.400"}
              _focus={{ border: "0px"}}
            >
              {t("menu.signUp")}
            </Button>
          </UnstyledLink>
        </Stack>
        <MenuIcon
          aria-label="Abrir menu"
          display={{base: "inherit", lg: "none"}}
          onClick={handleToggle}
        />
      </Stack>
      {isToggled && (
        <Drawer id="menu" size="full" onClose={handleToggle}>
          <DrawerBody backgroundColor="primary.900" color="white" paddingX={6} paddingY={6}>
            <Stack flex={1} justifyContent="space-between" spacing={6}>
              <Stack isInline alignItems="center" justifyContent="space-between">
                <Logo />
                <Icon name="close" onClick={handleToggle} />
              </Stack>
              <Stack flex={1} overflowY="auto" spacing={0}>
                {LINKS(t).map((link) => (
                  <Link
                    key={link.url}
                    borderBottomColor="primary.500"
                    borderBottomWidth={2}
                    href={link.url}
                    isExternal={link.isExternal}
                    paddingY={6}
                  >
                    <Heading fontSize="2xl">{link.label}</Heading>
                  </Link>
                ))}
              </Stack>
              <Stack
                isInline
                fontSize="sm"
                justifyContent="space-between"
                marginTop={6}
                spacing={2}
              >
                <Stack isInline alignItems="center" spacing={2}>
                  <Link
                    isExternal
                    _hover={{color: "primary.200"}}
                    href={`https://instagram.com/${t("menu.socialLinks.instagram")}`}
                    marginLeft={1}
                  >
                    <InstagramIcon size={{base: 6}} />
                  </Link>
                </Stack>
                <Stack isInline alignItems="center" spacing={2}>
                  <Link
                    isExternal
                    _hover={{color: "primary.200"}}
                    href={`https://wa.me/${process.env.MANTAINER_PHONE}`}
                    marginLeft={1}
                  >
                    <WhatsAppIcon size={{base: 6}} />
                  </Link>
                </Stack>
                <Stack isInline alignItems="center" spacing={2}>
                  <Link
                    isExternal
                    _hover={{color: "primary.200"}}
                    href={`https://facebook.com/${t("menu.socialLinks.facebook")}`}
                    marginLeft={1}
                  >
                    <FacebookIcon size={{base: 6}} />
                  </Link>
                </Stack>
                <Stack isInline alignItems="center" spacing={2}>
                  <Link
                    isExternal
                    _hover={{color: "primary.200"}}
                    href={`https://twitter.com/${t("menu.socialLinks.twitter")}`}
                    marginLeft={1}
                  >
                    <TwitterIcon size={{base: 6}} />
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </DrawerBody>
        </Drawer>
      )}
    </Content>
  );
};

export default Navbar;
